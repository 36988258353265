import { Outlet } from 'react-router-dom';
import Header from './modules/header/Header';
import Toaster from './uikit/toaster';
import { useLoginRedirect } from './utils/hooks/useLoginRedirect';
import { useEffect } from 'react';
import './utils/axiosConfig';
import Navigation from './modules/navigation/Navigation';
import { GlobalProvider } from './utils/contexts/GlobalContext';
import GlobalCode from './modules/globalcode';

export default function Layout() {
  const loginRedirect = useLoginRedirect();

  useEffect(() => {
    loginRedirect();
  }, [loginRedirect]);

  return (
    <>
      <div className="flex layout site-wrapper bg-[#F3F4F5] overflow-auto">
        <Navigation />
        <GlobalProvider>
          <main className="flex-1 flex-grow">
            <Header />
            <GlobalCode />
            <div className="md:p-7  bg-[#F3F4F5]">
              <Outlet />
            </div>
          </main>
        </GlobalProvider>
      </div>
      <Toaster />
    </>
  );
}

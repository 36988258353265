import { Navigate, Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';

const ManageWorkflow = lazy(() => import('../modules/admin/manageWorkflow'));
const ManageWorkflowTable = lazy(
  () => import('../modules/admin/manageWorkflow/ManageWorkflowTable')
);
const AddUpdateWorkflow = lazy(
  () => import('../modules/admin/manageWorkflow/AddUpdateWorkflow')
);

const WorkflowRoutes = (
  <>
    <Route
      path="workflow"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <ManageWorkflow />
        </Suspense>
      }
    >
      <Route
        path=":status/:action"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <AddUpdateWorkflow />
          </Suspense>
        }
      />
      <Route
        path=":status"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <ManageWorkflowTable />
          </Suspense>
        }
      />
      <Route
        path=""
        element={<Navigate to="/admin/workflow/published" replace />}
      />
    </Route>
  </>
);

export default WorkflowRoutes;

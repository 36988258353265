import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import moreSquare from './images/more-square.svg';
import styles from './NavigationFooter.module.scss';

const NavigationFooter = ({ menus }) => {
  const [showMore, setShowMore] = useState(false);

  // Include moreIcon in the menus array
  const extendedMenus = showMore
    ? [...menus, { title: 'Less', src: moreSquare, navigate: 'more' }]
    : [
        ...menus.slice(0, 4),
        { title: 'More', src: moreSquare, navigate: 'more' },
      ];

  return (
    <div
      className={`z-10 grid grid-cols-5 w-full bg-white px-3 max-xs:px-2 max-xs:pb-4 pb-6 duration-300 fixed bottom-0 border border-[#D1D9E8]`}
    >
      {extendedMenus.map((menu, index) => (
        <>
          {menu.navigate !== 'more' ? (
            <NavLink
              to={menu.navigate}
              onClick={() => setShowMore(false)}
              className={({ isActive, isPending }) =>
                `flex max-xs:mt-2 mt-3 flex-col cursor-pointer text-[#858E95] text-base items-center gap-1  
              ${menu.gap ? 'mt-9' : ''} ${
                index === 0 && 'bg-light-white'
              } ${isPending ? 'pending' : isActive ? `active ${styles.active}` : ''}`
              }

              // className={`flex mt-3 flex-col cursor-pointer text-[#858E95] text-base items-center gap-1
              // ${menu.gap ? 'mt-9' : ''} ${
              //   index === 0 && 'bg-light-white'
              // }`}
            >
              {menu?.src}
              <span className={`origin-left duration-200 text-[#858E95]`}>
                {menu.title}
              </span>
            </NavLink>
          ) : (
            <div
              className={`flex flex-col cursor-pointer text-[#858E95] text-base items-center gap-1 
              ${menu.gap ? 'mt-9' : 'mt-3 max-xs:mt-2'} ${index === 0 && 'bg-light-white'}`}
              onClick={() => setShowMore(!showMore)}
            >
              <img
                src={menu.src}
                className="max-xs:h-[20px] max-xs:w-[20px]"
                alt={menu?.title}
              />
              <span className={`origin-left duration-200 text-[#858E95]`}>
                {menu.title}
              </span>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

NavigationFooter.propTypes = {
  menus: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
    slice: PropTypes.func,
  }),
};
export default NavigationFooter;

const localStorageUtil = {
  // Set a value in localStorage
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting ${key} in localStorage: ${error.message}`);
    }
  },

  // Get a value from localStorage
  getItem: (key) => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
      console.error(`Error getting ${key} from localStorage: ${error.message}`);
      return null;
    }
  },

  // Remove a key from localStorage
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(
        `Error removing ${key} from localStorage: ${error.message}`
      );
    }
  },

  // Clear all items from localStorage
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(`Error clearing localStorage: ${error.message}`);
    }
  },
};

export default localStorageUtil;

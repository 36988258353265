import { useNavigate } from 'react-router-dom';
import localStorageUtil from '../../utils/localStorage';
import { API_STOMP_BASE_URL } from '../../apiConstant';
import Avatar from '../../uikit/avatar';
import useStomp from '../../utils/hooks/useStomp';
import { setValue, useGlobal } from '../../utils/contexts/GlobalContext';

function GlobalCode() {
  const navigate = useNavigate();
  const { state, dispatch } = useGlobal();

  // useEffect(() => {

  //   // Check if notification permission is already granted
  //   // if (Notification.permission !== 'granted') {
  //   //   // If not granted, request permission with a custom message
  //   //   Notification.requestPermission().then((permission) => {
  //   //     if (permission === 'granted') {
  //   //       console.log('Notification permission granted');
  //   //     }
  //   //   });
  //   // }
  // }, []);

  const userDetails = localStorageUtil.getItem('userDetails');
  const sockJSUrl = `${API_STOMP_BASE_URL}/ws`; // Replace with your SockJS URL
  const subscribeUrl = `/user/${userDetails?.username}_${userDetails?.uid}/private`;

  // const subscribeUrl = `/user/${userDetails?.username}_*/private`;

  const handleReceivedMessage = (receivedMsg) => {
    dispatch(
      setValue('unreadMessageCount', {
        ...state?.unreadMessageCount,
        [receivedMsg?.senderId]:
          (state?.unreadMessageCount?.[receivedMsg?.senderId] || 0) + 1,
      })
    );
    // Show desktop notification
    // if (Notification.permission === 'granted') {
    //   const notification = new Notification(
    //     `Message from ${receivedMsg?.senderName}`,
    //     {
    //       body: receivedMsg?.textMessage, // Set your message content here
    //       icon: '/apple-touch-icon.png',
    //     }
    //   );

    //   // Optional: Add click event listener to handle clicks on the notification
    //   notification.addEventListener('click', () => {
    //     window.open(
    //       `/message/personal/${receivedMsg?.senderId}/${receivedMsg?.receiverId}`,
    //       '_blank'
    //     );
    //     // Handle click event, e.g., navigate to a specific page
    //   });
    // } else {
    window.triggerAlert({
      children: (
        <div
          className="flex items-center max-w-sm p-2 mx-auto space-x-4 text-white bg-black rounded-lg shadow-md bg-opacity-20 backdrop-blur-md w-80"
          onClick={() =>
            navigate(
              `/message/personal/${receivedMsg?.senderId}/${receivedMsg?.receiverId}`
            )
          }
        >
          <div className="flex items-center justify-center">
            <Avatar
              text={
                receivedMsg?.senderFullName || receivedMsg?.senderName || ' '
              }
              textSize="20"
              imageUrl={''}
            />
          </div>

          <div className="flex-1">
            <h2 className="text-lg font-semibold">
              {receivedMsg?.senderFullName || receivedMsg?.senderName || ' '}
            </h2>
            <p className="mt-1 text-base line-clamp-1">
              {receivedMsg?.textMessage}
            </p>
          </div>
        </div>
      ),
      parentClassName: 'text-white',
    });
    // }
    // Handle the received message as needed
  };

  const handleStompError = (errorFrame) => {
    console.error('Error in STOMP connection:', errorFrame);
    // Handle the error as needed
  };

  const { sendMessage, isStompConnected } = useStomp(
    sockJSUrl,
    subscribeUrl,
    handleReceivedMessage,
    handleStompError,
    !!userDetails?.username
  );

  return <div></div>;
}

export default GlobalCode;

const levenshteinDistance = (a, b) => {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      const cost = a[j - 1] === b[i - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // Deletion
        matrix[i][j - 1] + 1, // Insertion
        matrix[i - 1][j - 1] + cost // Substitution
      );
    }
  }

  return matrix[b.length][a.length];
};

const getObjectValue = (obj, key) => {
  const keys = key.split('.');
  return keys.reduce(
    (acc, currentKey) => (acc && acc[currentKey] ? acc[currentKey] : ''),
    obj
  );
};

const fuzzyMatch = (str1, str2, threshold) => {
  const distance = levenshteinDistance(str1.toLowerCase(), str2.toLowerCase());
  return distance <= threshold;
};

const fuzzySearch = (collection, query, keys, threshold = 3) => {
  if (!collection || !query || !keys) {
    return collection;
  }
  const searchKey = JSON.stringify({ query, keys, threshold });

  // Simple caching using a Map
  if (fuzzySearch.cache.has(searchKey)) {
    return fuzzySearch.cache.get(searchKey);
  }

  const result = collection.filter((item) => {
    for (const key of keys) {
      const value = getObjectValue(item, key);
      if (typeof value === 'string' && fuzzyMatch(value, query, threshold)) {
        return true;
      }
    }
    return false;
  });

  // Memoize the result
  fuzzySearch.cache.set(searchKey, result);

  return result;
};

fuzzySearch.cache = new Map();

export default fuzzySearch;

// const keys = ["title", "author.firstName", "author.lastName"];
// const result = fuzzySearch(books, "man", keys);

import { Suspense, lazy } from 'react';
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from 'react-router-dom';
import Layout from '../Layout';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import ProjectRoutes from './ProjectRoutes';
import TaskRoutes from './TaskRoutes';
import MessageRoutes from './MessageRoutes';
import SettingRoutes from './SettingRoutes';
import AdminRoutes from './AdminRoutes';

const ErrorPage = lazy(() => import('../modules/error/ErrorPage'));

const Home = lazy(() => import('../modules/home'));

const Login = lazy(() => import('../modules/login'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        <Route
          index
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Home />
            </Suspense>
          }
        />
        {ProjectRoutes}
        {TaskRoutes}
        {MessageRoutes}
        {SettingRoutes}
        {AdminRoutes}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </>
  )
);

export default router;

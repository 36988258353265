import { useEffect, useRef, useMemo } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const useStomp = (
  sockJSUrl,
  subscribeUrl,
  onMessageReceived,
  onError,
  enabled = true
) => {
  const clientRef = useRef(null);
  const lastMessageRef = useRef(null); // To store the last received message

  const memoizedOnMessageReceived = useMemo(() => {
    return (receivedMsg) => {
      // Check if the received message is different from the last one
      if (
        JSON.stringify(receivedMsg) !== JSON.stringify(lastMessageRef.current)
      ) {
        onMessageReceived(receivedMsg);
        lastMessageRef.current = receivedMsg; // Update the last received message
      }
    };
  }, [onMessageReceived]);

  const memoizedOnError = useMemo(() => onError, [onError]);

  useEffect(() => {
    const initializeStomp = () => {
      if (clientRef.current && clientRef.current.connected) {
        return; // If the client is already connected, do not create a new instance
      }

      const client = new Client({
        webSocketFactory: () => new SockJS(sockJSUrl),
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
      });

      client.onConnect = (frame) => {
        client.subscribe(subscribeUrl, (message) => {
          const receivedMsg = JSON.parse(message.body);
          memoizedOnMessageReceived(receivedMsg);
        });
      };

      client.onStompError = (frame) => {
        memoizedOnError(frame);
      };

      client.activate();
      clientRef.current = client;
    };

    if (enabled) {
      initializeStomp();
    }

    // Cleanup when the component unmounts or when dependencies change
    return () => {
      if (clientRef.current && clientRef.current.connected) {
        clientRef.current.deactivate();
      }
      clientRef.current = null; // Reset clientRef
      lastMessageRef.current = null; // Reset lastMessageRef
    };
  }, [
    sockJSUrl,
    subscribeUrl,
    memoizedOnMessageReceived,
    memoizedOnError,
    enabled,
  ]);

  const sendMessage = (destination, headers, body) => {
    if (!clientRef.current || !clientRef.current.connected) {
      return;
    }

    clientRef.current.publish({
      destination,
      headers,
      body: JSON.stringify(body),
    });
  };

  const isStompConnected = clientRef.current?.connected || false;

  return { sendMessage, isStompConnected };
};

export default useStomp;

import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';
import ErrorPage from '../modules/error/ErrorPage';

const TaskManager = lazy(() => import('../modules/task-manager'));
const TaskPage = lazy(
  () => import('../modules/task-manager/components/task-pages')
);
const TaskDetails = lazy(() => import('../modules/task-details'));
const TaskDetailsPage = lazy(
  () => import('../modules/task-details/components/task-details-pages')
);
const SubTaskDetailsPage = lazy(
  () => import('../modules/subtask-details/components/sub-task-details-pages')
);
const SubTaskDetails = lazy(() => import('../modules/subtask-details'));

const TaskRoutes = (
  <>
    <Route
      path="/task-manager/:taskPage/:taskId"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <TaskDetails />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":taskManagerPage"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <TaskDetailsPage />
          </Suspense>
        }
      />
    </Route>
    <Route
      path="/task-manager"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <TaskManager />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":taskPage"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <TaskPage />
          </Suspense>
        }
      ></Route>
    </Route>
    <Route
      path="/subtask-manager/:taskPage/:subTaskId"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <SubTaskDetails />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":taskManagerPage"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <SubTaskDetailsPage />
          </Suspense>
        }
      />
    </Route>
  </>
);

export default TaskRoutes;

import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';
import ErrorPage from '../modules/error/ErrorPage';

const Project = lazy(() => import('../modules/project'));
const Page = lazy(() => import('../modules/project/components/pages'));
const ManageProject = lazy(() => import('../modules/manage/projects'));
const ProjectList = lazy(
  () => import('../modules/manage/projects/project-list')
);
const CreateProject = lazy(
  () => import('../modules/manage/projects/create-project')
);
const CreateProjectRoute = lazy(
  () => import('../modules/manage/projects/create-project/CreateProjectRoute')
);

const ProjectRoutes = (
  <>
    <Route
      path="/project/:pID"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <Project />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":page"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <Page />
          </Suspense>
        }
      />
    </Route>
    <Route
      path="/manage-project"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <CreateProject />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":pID/:projectPage"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <CreateProjectRoute />
          </Suspense>
        }
      />
    </Route>
    <Route
      path="/manage/project"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <ManageProject />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":projectStatus"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <ProjectList />
          </Suspense>
        }
      ></Route>
    </Route>
  </>
);

export default ProjectRoutes;

// Dev Environment

// export const API_PROJECT_BASE_URL = 'http://13.232.36.251:8082';

// export const API_TASK_BASE_URL = 'http://13.232.36.251:8083';

// export const API_USER_BASE_URL = 'http://13.232.36.251:8080';

// export const API_STOMP_BASE_URL = 'http://13.232.36.251:8081';

// export const API_S3_BASE_URL = 'http://13.232.36.251:8088';

// Prod Environment
export const API_PROJECT_BASE_URL = 'http://3.109.134.153:8082';

export const API_TASK_BASE_URL = 'http://3.109.134.153:8083';

export const API_USER_BASE_URL = 'http://3.109.134.153:8080';

export const API_STOMP_BASE_URL = 'http://3.109.134.153:8081';

export const API_S3_BASE_URL = 'http://3.109.134.153:8088';

// export const API_PROJECT_BASE_URL = '/api/p';

// export const API_TASK_BASE_URL = '/api/t';

// export const API_USER_BASE_URL = '/api/u';

// export const API_STOMP_BASE_URL = '/api/m';

import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';
import ErrorPage from '../modules/error/ErrorPage';

const Settings = lazy(() => import('../modules/settings'));

const SettingsContainer = lazy(
  () => import('../modules/settings/components/SettingsContainer')
);

const MessageContainer = lazy(
  () => import('../modules/message/components/MessageContainer')
);
const EmployeeDetails = lazy(
  () => import('../modules/admin/employees/pages/EmployeeDetails')
);

const SettingRoutes = (
  <>
    <Route
      path="/settings"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <Settings />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":settingsPage"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <SettingsContainer />
          </Suspense>
        }
      >
        <Route
          path=":details"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <EmployeeDetails />
            </Suspense>
          }
        >
          <Route
            path=":senderID"
            element={
              <Suspense fallback={<SkeletonPage />}>
                <MessageContainer />
              </Suspense>
            }
          >
            <Route
              path=":action"
              element={
                <Suspense fallback={<SkeletonPage />}>
                  <MessageContainer />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
    </Route>
  </>
);

export default SettingRoutes;

import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';
import ErrorPage from '../modules/error/ErrorPage';

const Message = lazy(() => import('../modules/message'));
const MessageContainer = lazy(
  () => import('../modules/message/components/MessageContainer')
);

const MessageRoutes = (
  <>
    <Route
      path="/message"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <Message />
        </Suspense>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path=":messagePage"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <MessageContainer />
          </Suspense>
        }
      >
        <Route
          path=":messageID"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <MessageContainer />
            </Suspense>
          }
        >
          <Route
            path=":senderID"
            element={
              <Suspense fallback={<SkeletonPage />}>
                <MessageContainer />
              </Suspense>
            }
          >
            <Route
              path=":action"
              element={
                <Suspense fallback={<SkeletonPage />}>
                  <MessageContainer />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
    </Route>
  </>
);

export default MessageRoutes;

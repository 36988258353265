import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

export function useQueryParam(param, replace = true) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [paramValue, setParamValue] = useState(searchParams.get(param) || '');

  useEffect(() => {
    const value = searchParams.get(param);
    setParamValue(value || '');
  }, [searchParams, param]);

  function setQueryParam(value) {
    if (!value) {
      removeQueryParam();
    } else {
      searchParams.set(param, value);
      setSearchParams(searchParams, { replace });
      setParamValue(value);
    }
  }

  function removeQueryParam() {
    searchParams.delete(param);
    setSearchParams(searchParams, { replace });
    setParamValue('');
  }

  return { paramValue, setQueryParam, removeQueryParam };
}

import axios from 'axios';
import { getCookie } from './cookies';

const accessToken = getCookie('authToken');

window.addEventListener('storage', () => {
  // When localStorage changes, refresh the authorization header
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
});

axios.defaults.timeout = 5000;

axios.interceptors.request.use(
  (config) => {
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    const originalRequest = error.config;
    // If the error is due to an Unauthorized request (401) and it's not a request to refresh the token
    if (error.response.status === 401 && !originalRequest._retry) {
      if (window.location.pathname !== '/login') {
        //TODO: Enable this for login
        window.location.replace(`/login?returnURL=${window.location.pathname}`);
      }

      // window.open('/login')
      // originalRequest._retry = true;
      // // Attempt to refresh the token
      // return axios.post('/auth/token/refresh')
      //     .then(res => {
      //         if (res.status === 201) {
      //             // 1) put token to localStorage
      //             localStorage.setItem('authToken', res.data.token);
      //             // 2) Change Authorization header
      //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
      //             // 3) return originalRequest object with Axios.
      //             return axios(originalRequest);
      //         }
      //     });
    }
    // If the error is due to something else, we just throw it back
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx cause this function to trigger
    // Do something with the response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with the response error
    if (error.response) {
      console.log('🚀 ~ error.response:', error.response);
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // TODO: Uncomment after demo
      window.triggerAlert({
        title: `${error?.response?.data?.message || `Something went wrong ${error.response.status}`}`,
        type: 'error',
      });
      // console.log(error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      // TODO: Uncomment after demo
      window.triggerAlert({ title: `No response received`, type: 'error' });
      console.log('No response received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    return Promise.reject(error);
  }
);

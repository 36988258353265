import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';
import ErrorPage from '../modules/error/ErrorPage';
import RolesRoutes from './RolesRoutes';
import CategoryRoutes from './CategoryRoutes';
import WorkflowRoutes from './WorkflowRoutes';
import EmployeeRoutes from './EmployeeRoutes';

const Roles = lazy(() => import('../modules/admin/roles'));
const Units = lazy(() => import('../modules/admin/units'));
const Department = lazy(() => import('../modules/admin/department'));

const AdminRoutes = (
  <>
    <Route
      path="/admin"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <Roles />
        </Suspense>
      }
    >
      {RolesRoutes}
      {CategoryRoutes}
      {WorkflowRoutes}
      <Route
        path="units"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <Units />
          </Suspense>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="department"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <Department />
          </Suspense>
        }
      />
      {EmployeeRoutes}
    </Route>
  </>
);

export default AdminRoutes;

import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../input';
import fuzzySearch from '../../utils/fuzzySearch';
import { useQueryParam } from '../../utils/hooks/useQueryParam';

const Search = ({
  placeholder,
  onChange,
  miniValue,
  loading,
  options,
  fuzzyKeys,
  onSelect,
  debouncing,
  throttling,
  endIcon,
  searchQuery = 'searchQuery',
  uiSearch = false,
  ...rest
}) => {
  const { paramValue, setQueryParam, removeQueryParam } =
    useQueryParam(searchQuery);
  const [modifiedOptions, setModifiedOptions] = useState(options);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [displayMiniView, setDisplayMiniView] = useState(miniValue);
  const timeoutIdRef = useRef(null);

  const handleSearch = (e) => {
    setQueryParam(e.target.value);
    if (debouncing) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        onChange(e);
        handleFuzzySearch(e.target.value);
      }, 500);
    } else if (throttling) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        onChange(e);
        handleFuzzySearch(e.target.value);
      }, 1000);
    } else {
      onChange(e);
      handleFuzzySearch(e.target.value);
    }
  };

  useEffect(() => {
    setModifiedOptions(options);
  }, [options]);

  const handleFuzzySearch = (val) => {
    if (uiSearch) {
      setModifiedOptions(fuzzySearch(options, val, fuzzyKeys));
    }
  };

  const handleSelect = (opt) => {
    setDisplayDropdown(false);
    removeQueryParam();
    onSelect(opt);
  };

  return (
    <>
      {displayMiniView ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          onClick={() => setDisplayMiniView(false)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8144 2.40015C6.1674 2.40015 2.40027 6.16726 2.40027 10.8142C2.40027 15.4611 6.1674 19.2283 10.8144 19.2283C12.8085 19.2283 14.6407 18.5345 16.0826 17.3752L20.0394 21.332C20.3965 21.689 20.9754 21.689 21.3324 21.332C21.6895 20.9749 21.6895 20.3961 21.3324 20.039L17.3756 16.0822C18.5348 14.6403 19.2284 12.8083 19.2284 10.8142C19.2284 6.16726 15.4613 2.40015 10.8144 2.40015ZM4.22882 10.8142C4.22882 7.17715 7.17728 4.2287 10.8144 4.2287C14.4514 4.2287 17.3999 7.17715 17.3999 10.8142C17.3999 14.4513 14.4514 17.3997 10.8144 17.3997C7.17728 17.3997 4.22882 14.4513 4.22882 10.8142Z"
            fill="#292929"
          />
        </svg>
      ) : (
        <div className="relative">
          <Input
            type="text"
            onClick={() => setDisplayDropdown(true)}
            placeholder={placeholder}
            value={paramValue || ''}
            onChange={handleSearch}
            disabled={loading}
            {...rest}
          />

          {endIcon && (
            <div className="absolute inset-y-0 right-0 flex items-center px-4">
              {endIcon}
            </div>
          )}
          {loading && (
            <div
              className={`absolute inset-y-0 ${endIcon ? 'right-5' : 'right-0'} flex items-center px-4`}
            >
              <svg
                className="w-5 h-5 text-gray-500 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20a8 8 0 01-8-8H0c0 6.627 5.373 12 12 12v-4zm3-5.291A7.962 7.962 0 0120 12h-4c0 3.042-1.135 5.824-3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
          {uiSearch && paramValue?.length > 2 && displayDropdown && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-400 rounded-lg shadow-lg">
              {modifiedOptions.map((option) => (
                <div
                  key={option.uid}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                  onClick={() => handleSelect(option)}
                >
                  {option.username || option.name}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  searchQuery: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fuzzyKeys: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func,
  debouncing: PropTypes.bool,
  throttling: PropTypes.bool,
  uiSearch: PropTypes.bool,
  miniValue: PropTypes.bool,
  endIcon: PropTypes.element,
};

Search.defaultProps = {
  placeholder: 'Search...',
  loading: false,
  options: [],
  debouncing: false,
  throttling: false,
  miniValue: false,
};

export default Search;

export function setCookie(
  name,
  value,
  minutes = null,
  path = '/',
  domain = null,
  secure = null
) {
  let cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  if (minutes) {
    let date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    cookie += '; expires=' + date.toUTCString();
  }
  if (path) {
    cookie += '; path=' + path;
  }
  if (domain) {
    cookie += '; domain=' + domain;
  }
  if (secure) {
    cookie += '; secure';
  }
  document.cookie = cookie;
}

// get a cookie and Its value
export function getCookie(cName) {
  if (typeof document !== 'undefined') {
    const name = `${cName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  return '';
}

// pass the name of the cookie as string which you want to check that if It exists or not.
export function checkCookie(cookieName) {
  const cookieExist = getCookie(cookieName);
  if (cookieExist !== '') {
    return cookieExist;
  }
  return false;
}

export function deleteCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

import PropTypes from 'prop-types';

const Input = ({
  label,
  type,
  labelPlacement = 'top',
  placeholder,
  value,
  onChange,
  required,
  error,
  inputClassName,
  labelClassName,
  parentClassName,
  errorClassName,
  ...rest
}) => {
  return (
    <div
      className={`flex ${labelPlacement === 'top' ? 'flex-col' : 'items-center gap-8 w-full'} ${parentClassName} text-base`}
    >
      {label && (
        <label className={`text-gray-700 font-bold mb-2 ${labelClassName}`}>
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <input
        className={`border border-gray-400 text-black p-2 rounded-lg  ${labelPlacement === 'top' ? '' : 'w-full'} ${inputClassName} ${
          error ? 'border-red-500' : ''
        }`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        {...rest}
      />
      {error && (
        <p className={`mt-1 text-base text-red-500 ${errorClassName}`}>
          {error}
        </p>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  inputClassName: PropTypes.string,
  labelPlacement: PropTypes.string,
  labelClassName: PropTypes.string,
  parentClassName: PropTypes.string,
  errorClassName: PropTypes.string,
};

export default Input;

import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';

const RoleList = lazy(() => import('../modules/admin/roles/role-list'));
const ManageRoles = lazy(() => import('../modules/admin/roles/manage-roles'));

const RolesRoutes = (
  <>
    <Route
      path="roles"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <RoleList />
        </Suspense>
      }
    />
    <Route
      path="roles/manage-roles"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <ManageRoles />
        </Suspense>
      }
    >
      <Route
        path=":roleID"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <ManageRoles />
          </Suspense>
        }
      />
    </Route>
  </>
);

export default RolesRoutes;

import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';
import ErrorPage from '../modules/error/ErrorPage';

const ManageCategory = lazy(() => import('../modules/admin/manageCategory'));
const ManageCategoryTable = lazy(
  () => import('../modules/admin/manageCategory/ManageCategoryTable')
);
const AddUpdateCategory = lazy(
  () => import('../modules/admin/manageCategory/AddUpdateCategory')
);

const CategoryRoutes = (
  <>
    {' '}
    <Route
      path="category"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <ManageCategory />
        </Suspense>
      }
    >
      <Route
        path=":action"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <AddUpdateCategory />
          </Suspense>
        }
      >
        <Route
          path=":type"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <AddUpdateCategory />
            </Suspense>
          }
        />
      </Route>
      <Route
        path=""
        element={
          <Suspense fallback={<SkeletonPage />}>
            <ManageCategoryTable />
          </Suspense>
        }
      />
    </Route>
  </>
);

export default CategoryRoutes;

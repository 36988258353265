import { useNavigate } from 'react-router-dom';
import { getCookie } from '../cookies';

export function useLoginRedirect() {
  const navigate = useNavigate();

  return function () {
    const accessTokenLocal = getCookie('authToken');
    const location = window.location.pathname;
    if (!accessTokenLocal && location !== '/login') {
      //TODO: Enable this for login
      navigate(`/login?returnURL=${location}`);
    }
  };
}

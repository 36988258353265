import { createPortal } from 'react-dom';
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../button';

function Modal({
  title,
  onClose,
  backdrop = true,
  children,
  footer,
  position = 'middle',
  transition = 'ease-in-out',
  duration = 300,
  isOpen = true,
  showCloseButton = true,
  footerPosition = 'right',
  containerClz,
  childrenClassName,
}) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
        onClose();
      }, duration);
    }
  }, [duration, onClose, isOpen]);

  function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  const modal = (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className={`${backdrop ? 'bg-black bg-opacity-50' : ''} fixed inset-0`}
        onClick={handleBackdropClick}
      ></div>
      <div
        className={`${
          position === 'middle'
            ? 'md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bottom-0 md:bottom-auto'
            : position === 'left'
              ? 'left-0'
              : 'right-0'
        } md:min-w-[400px] fixed z-10 bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 ${transition} ${
          isClosing ? 'opacity-0' : ''
        } ${containerClz ? containerClz : ''}`}
      >
        <div className="flex items-center justify-between p-4 ">
          {title && (
            <Fragment>
              {typeof title === 'string' ? (
                <h3 className="text-lg font-medium text-gray-900">{title}</h3>
              ) : (
                title
              )}
            </Fragment>
          )}
          {showCloseButton && (
            <Button
              variant="secondaryContained"
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.71967 5.71967C6.01256 5.42678 6.48744 5.42678 6.78033 5.71967L12 10.9393L17.2197 5.71967C17.5126 5.42678 17.9874 5.42678 18.2803 5.71967C18.5732 6.01256 18.5732 6.48744 18.2803 6.78033L13.0607 12L18.2803 17.2197C18.5732 17.5126 18.5732 17.9874 18.2803 18.2803C17.9874 18.5732 17.5126 18.5732 17.2197 18.2803L12 13.0607L6.78033 18.2803C6.48744 18.5732 6.01256 18.5732 5.71967 18.2803C5.42678 17.9874 5.42678 17.5126 5.71967 17.2197L10.9393 12L5.71967 6.78033C5.42678 6.48744 5.42678 6.01256 5.71967 5.71967Z"
                    fill="#FF8C00"
                    stroke="#FF8C00"
                    strokeLinecap="round"
                  />
                </svg>
              }
              onClick={onClose}
            />
          )}
        </div>
        {title && <hr className="mx-4 border-gray-200" />}
        <div className={`md:p-6 p-3 ${childrenClassName}`}>{children}</div>
        {footer && (
          <div
            className={`${
              footerPosition === 'left'
                ? 'justify-start'
                : footerPosition === 'right'
                  ? 'justify-end'
                  : 'justify-center'
            } p-4 flex`}
          >
            {footer}
          </div>
        )}
      </div>
    </div>
  );

  const modalRoot =
    document.getElementById('storybook-root') ||
    document.getElementById('modal-root');

  if (!modalRoot) {
    console.error(
      "Error: Could not find element with id 'modal-root'. Make sure it exists in the DOM."
    );
    return null;
  }

  return createPortal(isOpen ? modal : null, modalRoot);
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func.isRequired,
  backdrop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  footer: PropTypes.element,
  position: PropTypes.oneOf(['left', 'right', 'middle']),
  transition: PropTypes.string,
  duration: PropTypes.number,
  isOpen: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  footerPosition: PropTypes.oneOf(['left', 'right']),
  containerClz: PropTypes.string,
  childrenClassName: PropTypes.string,
};

export default Modal;

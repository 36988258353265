import { useState } from 'react';
import Button from '../../uikit/button';
import errorImage from './images/generic-error.png';
import Modal from '../../uikit/modal';

const ErrorPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }
  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 screen-minus-header">
      <h1 className="text-4xl font-bold text-gray-800">
        Oops! Something went wrong.
      </h1>
      <p className="mt-4 text-lg text-gray-600">
        We apologize for the inconvenience.
      </p>
      <img
        src={errorImage}
        alt="Error Illustration"
        className="w-64 h-auto mt-8"
      />
      <Button onClick={handleOpen}>Go back to homepage</Button>
      <Modal
        title="Example Modal"
        onClose={handleClose}
        backdrop={true}
        position="middle"
        transition="ease-in-out"
        duration={300}
        footer={
          <button className="px-4 py-2 text-white bg-blue-500 rounded-lg">
            Save
          </button>
        }
        footerPosition="center"
        isOpen={isOpen}
      >
        <p>
          This is the body of the modal. You can put any content you want here.
        </p>
      </Modal>
    </div>
  );
};

export default ErrorPage;

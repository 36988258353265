import { useState, useEffect } from 'react';
import logo from './images/logo.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { useGlobal } from '../../utils/contexts/GlobalContext';
import Search from '../../uikit/search';
function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const { state } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="sticky top-0 z-40 flex-none w-full px-2 py-4 transition-colors duration-500 bg-white border-b max-xs:py-2 md:px-8 drop-shadow backdrop-blur border-slate-900/10 ">
      <div className="flex items-center justify-between">
        <div className="flex items-center h-9 gap-x-4 max-xs:gap-x-2">
          <div className="md:hidden" onClick={() => navigate(-1)}>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#000000"
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
              />
              <path
                fill="#000000"
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
              />
            </svg>
          </div>
          <NavLink to={`/`}>
            <img alt="logo" width={32} height={32} src={logo} />
          </NavLink>
          <NavLink to={`/`}>
            <h1 className={`text-black origin-left font-medium text-xl`}>
              Nakhsha
            </h1>
          </NavLink>
        </div>
        {state?.showSearch ? (
          <>
            <Search
              name="search"
              miniValue={true}
              searchQuery={state?.searchQuery || null}
              type="text"
              placeholder="Search Keyword"
              parentClassName="ml-2"
              onChange={() => {}}
            />
          </>
        ) : null}
      </div>
    </header>
  );
}

export default Header;

import { Route } from 'react-router-dom';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import { Suspense, lazy } from 'react';

const Employees = lazy(() => import('../modules/admin/employees'));

const EmployeesTable = lazy(
  () => import('../modules/admin/employees/pages/EmployeesTable')
);
const EmployeeDetails = lazy(
  () => import('../modules/admin/employees/pages/EmployeeDetails')
);

const EmployeeRoutes = (
  <>
    {' '}
    <Route
      path="employees"
      element={
        <Suspense fallback={<SkeletonPage />}>
          <Employees />
        </Suspense>
      }
    >
      <Route
        path=":eId/:action/:details?"
        element={
          <Suspense fallback={<SkeletonPage />}>
            <EmployeeDetails />
          </Suspense>
        }
      />
      <Route
        path=""
        element={
          <Suspense fallback={<SkeletonPage />}>
            <EmployeesTable />
          </Suspense>
        }
      />
    </Route>
  </>
);

export default EmployeeRoutes;

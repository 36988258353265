import { useState, useEffect } from 'react';
import { getToasterQueue, removeAlert } from './toaster';
import { toastHelper } from './constants';

const Toaster = () => {
  const [toasts, setToasts] = useState([]);

  const updateToasts = () => {
    const queue = getToasterQueue();
    setToasts([...queue]);
  };

  useEffect(() => {
    // Update toasts when the component is mounted
    updateToasts();

    const interval = setInterval(() => {
      updateToasts();
    }, 10);

    return () => clearInterval(interval);
  }, []);

  const removeAndRefresh = (id) => {
    removeAlert(id);
    updateToasts();
  };

  return (
    <div className="fixed top-0 z-50 p-4 right-5">
      {toasts.map((toast) => (
        <>
          <div
            key={toast.id}
            className={`relative inline-flex flex-col items-start shadow-md transition-transform transform justify-start gap-2 ml-2 mb-2 rounded-lg ${toast?.children ? '' : `pl-4 pr-8 py-2 ${toastHelper?.[toast.type]?.backgroundColor}`} ${toast?.parentClassName}`}
          >
            <button
              className="absolute top-0 float-right right-2 z-[1]"
              onClick={() => removeAndRefresh(toast.id)}
            >
              &times;
            </button>
            {toast?.children ? (
              toast?.children
            ) : (
              <>
                <div className="inline-flex items-center justify-start gap-2">
                  <div className="flex items-center justify-start gap-2">
                    <div className="relative w-5 h-5">
                      {toastHelper?.[toast.type]?.icon}
                    </div>
                    <div
                      className={` text-base font-semibold leading-[18px] ${toastHelper?.[toast.type]?.textColor}`}
                    >
                      {toast.title}
                    </div>
                  </div>
                </div>
                <div className="opacity-90 text-zinc-800 text-base font-medium leading-[14px]">
                  {toast.message}
                </div>
              </>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default Toaster;
